<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(detail)}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol md="3">
        <MDBInput :label="$t('COMMON.ORDERNUMBER')" :placeholder="$t('COMMON.ORDERNUMBER')" v-model="where.order" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.MEMBERNAME')" :placeholder="$t('COMMON.MEMBERNAME')" v-model="where.name" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.EMAIL')" :placeholder="$t('COMMON.EMAIL')" v-model="where.email" />
      </MDBCol>
      <MDBCol md="2">
        <select class="form-select" v-model="where.status">
          <option value="">訂單狀態</option>
          <option value="0">{{$t("ORDER.STATUS_0")}}</option>
          <option value="1">{{$t("ORDER.STATUS_1")}}</option>
          <option value="2">{{$t("ORDER.STATUS_2")}}</option>
          <!-- <option value="3">{{$t("ORDER.STATUS_3")}}</option> -->
          <option value="4">{{$t("ORDER.STATUS_4")}}</option>
          <option value="5">{{$t("ORDER.STATUS_5")}}</option>
        </select>
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <template v-slot:status="{ item }">
        <div class="badge  text-wrap" :class="{ 'bg-danger': (item.status == 0 || item.status == 2),'bg-success': (item.status == 1),'bg-secondary': (item.status == 3),'bg-warning': (item.status == 5) }">
          {{ $t("ORDER.STATUS_" + item.status) }}
        </div>
      </template>
      <template v-slot:order_really_total="{ item }">
        {{ item.order_really_total }} {{item.currency}}
      </template>
      <template v-slot:order_total="{ item }">
        {{ item.order_total }} {{item.currency}}
      </template>
      <template v-slot:order_promo_total="{ item }">
        {{ item.order_promo_total }} {{item.currency}}
      </template>
      <!-- 操作template -->
      <template v-slot:uuid="{ item }">
        <MDBBtn color="primary" @click="DetailUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="eye" iconStyle="fas" class="text-white"/>
        </MDBBtn>
				<!-- <a @click="DetailUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('ORDER.DETAIL')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="eye" iconStyle="fas" class="text-primary"/>
				</a> -->
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
	<OrderDetail v-model="detail" v-model:uuid="DetailUUid"></OrderDetail>
</template>

<script>
import { MDBContainer, MDBIcon, MDBCard, MDBCardBody, MDBBtn, MDBRow, MDBCol, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import OrderDetail from '@/components/OrderDetail';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "Order",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		OrderDetail,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput,
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const detail = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const DetailUUid = ref("");
    const desserts = ref([]);

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const where = reactive({
      order: "",
      name: "",
      email: "",
      status: "",
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width:50
      },
      { text: i18n.t("COMMON.ORDERTIME"), value: "created_at",width:112 },
      { text: i18n.t("COMMON.ORDERNUMBER"), value: "order" },
      { text: i18n.t("COMMON.MEMBERNAME"), value: "name" },
      { text: i18n.t("COMMON.EMAIL"), value: "email" },
      { text: i18n.t("COMMON.ORDERCOUNT"), value: "order_total" },
      { text: i18n.t("COMMON.ORDERPROMO"), value: "order_promo_total" },
      { text: i18n.t("COMMON.ORDERPTOTAL"), value: "order_really_total" },
      { text: i18n.t("COMMON.ORDERPSTATUS"), value: "status" },
      { text: i18n.t("COMMON.OPERATE"), value: "uuid", sortable: false }
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Order",
          {
            params: Object.assign(CommonService.SortHandleObject(options),where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

		return {
      PageLoading,
      detail,
      loading,
      total,
      Common,
      options,
      headers,
      desserts,
      DetailUUid,
      GetTable,
      where,
    };
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
  },
  mounted() {
    this.GetTable();
  }
};
</script>